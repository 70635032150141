import { Button, Card, CardContent, Text } from "@fscrypto/ui";
import { rewards } from "@fscrypto/domain";
import { DateTime } from "luxon";
import { roundRewardsAmount } from "~/utils/helpers";
import { tracking } from "~/utils/tracking";

export const HistoryCard = ({ payment }: { payment: rewards.PaymentHistory }) => {
  const transactionLink = rewards.RewardsService.generateBlockExplorerLink(payment.paymentRecord);
  return (
    <Card variant="surface">
      <CardContent padding="lg">
        <div className="mb-2 flex flex-wrap items-center justify-between gap-1">
          <Text color="contrast" weight={"medium"}>
            {payment.title ?? payment.questSlug}
          </Text>
          <p className="text-xs text-neutral-700 dark:text-neutral-300">
            {DateTime.fromJSDate(payment.paymentRecord.createdAt).toFormat("MM/dd/yy")}
          </p>
        </div>
        <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
          <div>
            <Text color="success" size="sm">
              {roundRewardsAmount(payment.paymentRecord.amount)} {payment.paymentRecord.currency}
            </Text>
          </div>
          {transactionLink && (
            <a
              href={transactionLink}
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => tracking("click_view_transaction", "Earn")}
            >
              <Button variant="secondary" size="md">
                View Txn
              </Button>
            </a>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
